function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"dashboard-view","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('material-card',{attrs:{"color":"success","icon":"mdi-earth","icon-small":"","inline":"","title":"Global Sales by Top Locations"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"mt-10",attrs:{"cols":"12","md":"6"}},[_c('v-simple-table',{staticClass:"ml-2"},[_c('tbody',_vm._l((_vm.sales),function(sale,i){return _c('tr',{key:i},[_c('td',[_c('v-img',{attrs:{"src":sale.flag,"width":"18"}})],1),_c('td',{domProps:{"textContent":_vm._s(sale.country)}}),_c('td',{staticClass:"text-right",domProps:{"textContent":_vm._s(sale.salesInM)}}),_c('td',{staticClass:"text-right",domProps:{"textContent":_vm._s(((sale.salesInM / _vm.totalSales) * 100).toFixed(2) + '%')}})])}),0)])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-responsive',{attrs:{"max-height":"400"}},[_c('v-world-map',{staticClass:"pa-4",attrs:{"country-data":_vm.countryData,"high-color":"#838383","low-color":"#BBBBBB"}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',_vm._l((_vm.charts),function(chart,i){return _c('v-col',{key:("chart-" + i),attrs:{"cols":"12","md":"6","lg":"4"}},[_c('material-chart-card',{attrs:{"color":chart.color,"data":chart.data,"options":chart.options,"responsive-options":chart.responsiveOptions,"title":chart.title,"type":chart.type,"hover-reveal":""},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('div',{staticClass:"font-weight-light text--secondary"},[_c('div',{domProps:{"innerHTML":_vm._s(chart.subtitle)}})])]},proxy:true},{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-view-split-vertical ")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" $edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error","size":"18"}},[_vm._v(" $clear ")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove")])])]},proxy:true},{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-clock-outline ")]),_c('span',{staticClass:"text-caption grey--text font-weight-light",domProps:{"textContent":_vm._s(chart.time)}})]},proxy:true}],null,true)})],1)}),1)],1),_vm._l((_vm.stats),function(ref,i){
var actionIcon = ref.actionIcon;
var actionText = ref.actionText;
var rest = objectWithoutProperties( ref, ["actionIcon", "actionText"] );
var attrs = rest;
return _c('v-col',{key:i,attrs:{"cols":"12","md":"6","lg":"3"}},[_c('material-stat-card',_vm._b({scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},domProps:{"textContent":_vm._s(actionIcon)}}),_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(actionText)+" ")])]},proxy:true}],null,true)},'material-stat-card',attrs,false))],1)}),_c('v-col',{attrs:{"cols":"12"}}),_vm._l((_vm.listings),function(listing,i){return _c('v-col',{key:("listings-" + i),attrs:{"cols":"12","md":"4"}},[_c('material-reveal-card',{staticClass:"text-center",attrs:{"title":listing.title},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-img',{attrs:{"src":("https://demos.creative-tim.com/vue-material-dashboard-pro/img/card-" + (listing.image) + ".jpg")}})]},proxy:true},{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-view-split-vertical ")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" $edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error","size":"18"}},[_vm._v(" $clear ")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove")])])]},proxy:true},{key:"actions",fn:function(){return [_c('span',{staticClass:"text-h4",domProps:{"textContent":_vm._s(listing.price)}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-auto mr-1",attrs:{"small":""}},'v-icon',!_vm.$vuetify.breakpoint.mobile ? {} : attrs,false),!_vm.$vuetify.breakpoint.mobile ? {} : on),[_vm._v(" mdi-map-marker ")])]}}],null,true)},[_vm._v(" "+_vm._s(listing.location)+" ")]),_c('span',{staticClass:"text-caption grey--text font-weight-light hidden-md-and-down",domProps:{"textContent":_vm._s(listing.location)}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(listing.text)+" ")])],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }